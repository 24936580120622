<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-around">
            <div class="card-body mb-3 pb-0 col-12"><h2 class="fw-400 font-lg d-block"><b>Everyone</b></h2></div>
            <div class="col-10">
                <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
            </div>
            <div class="col-2">
                <select class="form-control" v-model="role_id">
                    <option value="">All Roles</option>
                    <option value="1">Administrator</option>
                    <option value="2">Student</option>
                    <option value="3">Teacher</option>
                </select>
            </div>
            <div class="card-body w-100">
                <div class="row">
                    <div v-if="!isLoad" class="col-12 py-3">
                        <div class="table-responsive mw-100 pr-2 ml-0">
                            <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="data" :page="1" :perPage="10">
                                <template name="default" slot-scope="{ row, index }">
                                    <tr v-if="row">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ row.user.name }}</td>
                                        <td>{{ row.user.email }}</td>
                                        <td>{{ row.user.username }}</td>
                                        <td>{{ row.user.role_id ? row.user.role.name : ''}}</td>
                                        <td><div class="btn text-white" :class="{'bg-success': row.cycle_1, 'bg-danger': !row.cycle_1}"><i class="fas" :class="{'fa-check': row.cycle_1, 'fa-times': !row.cycle_1}"></i></div></td>
                                        <td><div class="btn text-white" :class="{'bg-success': row.cycle_2, 'bg-danger': !row.cycle_2}"><i class="fas" :class="{'fa-check': row.cycle_2, 'fa-times': !row.cycle_2}"></i></div></td>
                                        <td><div class="btn text-white" :class="{'bg-success': row.cycle_3, 'bg-danger': !row.cycle_3}"><i class="fas" :class="{'fa-check': row.cycle_3, 'fa-times': !row.cycle_3}"></i></div></td>
                                    </tr>
                                    <tr v-else>
                                        <td colspan="8">Nothing to see here</td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>
                        <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                    </div>
                    <div v-else class="col-12 text-center py-3">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Everyone',
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            isLoad: true,
            role_id: '',
            data: [],
            page: 1,
            tableFilter: '',
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Name', field: 'user.name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Email', field: 'user.email', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Username', field: 'user.username', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Role', field: 'row.user.role.name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},                
                {label: 'Cycle 1', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},                
                {label: 'Cycle 2', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},                
                {label: 'Cycle 3', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},                
            ]
        }
    },
    created(){
        this.getEverone()
    },
    methods:{
        async getEverone(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/people/everyone?slug=${this.paramsId}&role=${this.role_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data
                this.isLoad = false
            })
        }
    },
    watch: {
        role_id(){
            this.getEverone()
        }
    }
}
</script>