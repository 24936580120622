<template>
    <div class="row justify-content-center">
        <div class="col-lg-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                    <li class="list-inline-item"><a :class="{'active': tab == '#people'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#people">Everyone</a></li>
                    <li class="list-inline-item"><a :class="{'active': tab == '#group'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#group">Group</a></li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" v-if="tab == '#people'">
                    <everyone></everyone>
                </div>
                <div class="tab-pane fade show active" v-if="tab == '#group'">
                    <group></group>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Everyone from './Everyone/Everyone.vue'
import Group from './Group/Group.vue'

export default {
    components:{
        Everyone,
        Group,
    },
    mounted() {
        if (this.$route.hash) {
            this.tab = this.$route.hash
        }
    },
    data() {
        return{
            paramsId: this.$route.params.idCourse,
            tab: '#people'
        }
    },
}
</script>
